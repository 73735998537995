import Cookie from "js-cookie";
/**
 * Get current user decision for Google Consent Mode from the cookie value.
 */ function getUserGcmDecision(cookieName) {
    // Got it saved locally?
    const localConsent = localStorage.getItem(cookieName);
    if (localConsent) {
        return JSON.parse(localConsent);
    }
    const cookieValue = Cookie.get(cookieName);
    return JSON.parse(cookieValue || "[]");
}
export { getUserGcmDecision };
