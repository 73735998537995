import { getDefaultDecision, getUserDecision } from "..";
import { yieldMainThread } from "@devowl-wp/react-utils";
/**
 * Main class to manage cookie consents within your application.
 */ class CookieConsentManager {
    static #_ = this.BROADCAST_SIGNAL_APPLY_COOKIES = "applyCookies";
    /**
     * A global broadcast channel for this cookie consent management.
     */ get broadcastChannel() {
        this._boradcastChannel = window.BroadcastChannel ? this._boradcastChannel || new BroadcastChannel("@devowl-wp/cookie-consent-web-client") : undefined;
        return this._boradcastChannel;
    }
    constructor(options){
        var _this_broadcastChannel;
        const { decisionCookieName } = options;
        this.options = options;
        this.options.tcfCookieName = `${decisionCookieName}-tcf`;
        this.options.gcmCookieName = `${decisionCookieName}-gcm`;
        (_this_broadcastChannel = this.broadcastChannel) == null ? void 0 : _this_broadcastChannel.addEventListener("message", (param)=>{
            let { data } = param;
            if (data === CookieConsentManager.BROADCAST_SIGNAL_APPLY_COOKIES) {
                this.applyCookies({
                    type: "consent"
                }, false);
            }
        });
        const fn = async ()=>{
            const { retryPersistFromQueue } = await import(/* webpackChunkName: "banner-lazy", webpackMode: "lazy-once" */ "../decision/retryPersistFromQueue");
            // Only retry when the queue is filled with items
            const start = (tryImmediate)=>{
                const dispose = retryPersistFromQueue(this, tryImmediate);
                window.addEventListener("unload", dispose);
            };
            if (this.getConsentQueue().length > 0) {
                start(true);
            } else {
                const listener = (param)=>{
                    let { key, newValue } = param;
                    const fromCurrentTab = key === this.getConsentQueueName() && newValue;
                    const fromOtherTab = key === this.getConsentQueueName(true) && !newValue;
                    if (fromCurrentTab || fromOtherTab) {
                        start(fromOtherTab);
                        window.removeEventListener("storage", listener);
                    }
                };
                window.addEventListener("storage", listener);
            }
        };
        if (window.requestIdleCallback) {
            requestIdleCallback(fn);
        } else {
            yieldMainThread().then(fn);
        }
    }
    async applyCookies(options, broadcast) {
        if (broadcast === void 0) broadcast = true;
        const { apply: doApply } = await import(/* webpackChunkName: "banner-lazy", webpackMode: "lazy-once" */ "../apply/apply");
        await doApply({
            ...options,
            ...this.options
        });
        if (broadcast) {
            var _this_broadcastChannel;
            (_this_broadcastChannel = this.broadcastChannel) == null ? void 0 : _this_broadcastChannel.postMessage(CookieConsentManager.BROADCAST_SIGNAL_APPLY_COOKIES);
        }
    }
    /**
     * If you have passed a `persistConsent` as option to the manager constructor, you can use this method.
     * This method wraps your passed callback and if an error occurs, it accordingly handles the error and pushes
     * the transaction into a queue. The queue has a lifecycle to get retried at a later stage when e.g. your server
     * is available again.
     */ async persistConsent(transaction) {
        const { persistWithQueueFallback } = await import(/* webpackChunkName: "banner-lazy", webpackMode: "lazy-once" */ "../decision/persistWithQueueFallback");
        return await persistWithQueueFallback(transaction, this);
    }
    getUserDecision(onlyUptoDate) {
        const decision = getUserDecision(this.getOption("decisionCookieName"));
        return onlyUptoDate === true ? decision ? decision.revision === this.getOption("revisionHash") ? decision : false : false : decision;
    }
    getDefaultDecision(respectLegitimateInterests) {
        if (respectLegitimateInterests === void 0) respectLegitimateInterests = true;
        return getDefaultDecision(this.options.groups, respectLegitimateInterests);
    }
    getOption(name) {
        return this.options[name];
    }
    getOptions() {
        return this.options;
    }
    getConsentQueueName(lock) {
        if (lock === void 0) lock = false;
        return `${this.options.consentQueueLocalStorageName}${lock ? "-lock" : ""}`;
    }
    getConsentQueue() {
        return JSON.parse(localStorage.getItem(this.getConsentQueueName()) || "[]");
    }
    setConsentQueue(queue) {
        const key = this.getConsentQueueName();
        const oldValue = localStorage.getItem("test");
        const newValue = queue.length > 0 ? JSON.stringify(queue) : null;
        if (newValue) {
            localStorage.setItem(key, newValue);
        } else {
            localStorage.removeItem(key);
        }
        // Send custom event so the current browser can listen to the queue change (see constructor)
        // https://stackoverflow.com/a/72428465/5506547
        window.dispatchEvent(new StorageEvent("storage", {
            key,
            oldValue,
            newValue
        }));
    }
    isConsentQueueLocked(set) {
        const current = new Date().getTime();
        const lockName = this.getConsentQueueName(true);
        if (set === false) {
            localStorage.removeItem(lockName);
        } else if (set === true) {
            localStorage.setItem(lockName, `${current + 1000 * 60}`);
        }
        const time = +(localStorage.getItem(lockName) || 0);
        return !(current > time);
    }
}
export { CookieConsentManager };
